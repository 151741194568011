/* eslint-disable react/display-name */
import React from 'react'

export default function NotFound() {
  return (
    <div>
      Nothing to see here{' '}
      <span role="img" aria-labelledby="boxing glove">
        🥊
      </span>
    </div>
  )
}
